import { useFormikContext } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TFunction } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import sanitizeHtml from 'sanitize-html';

// Ui-kit
import Button from 'ui-kit/button/button';
import AlertIcon from 'ui-kit/icons/alert/alert-icon';
import Text from 'ui-kit/text/text';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Components
import { CircleDone } from 'components/circle-done';

// Types
import { MembershipRegistrationFormValidateInsuranceIdProps } from 'types/membership';

// Util
import { getPhoneNumber } from 'util/globalVariables';

// Hooks
import { useGlobalLink } from 'hooks/useGlobalLink';

import './membership-registration-insurance-id-validate.style.scss';

interface InsuranceStatusProps {
    validateStatus: MembershipRegistrationFormValidateInsuranceIdProps['validateStatus'];
    textClass: string;
    textClassInvalid: string;
    textClassRegistered: string;
    t: TFunction;
}

function InsuranceStatus({
    validateStatus,
    textClass,
    textClassInvalid,
    textClassRegistered,
    t
}: InsuranceStatusProps) {
    let status = null;

    switch (validateStatus) {
        case 'valid':
            return (
                <ToastBox variant="success">
                    <CircleDone />
                    <p>{t(textClass)}</p>
                </ToastBox>
            );
        case 'invalid':
            return (
                <ToastBox variant="warning">
                    <AlertIcon variant="warning" />
                    <p>{t(textClassInvalid)}</p>
                </ToastBox>
            );
        case 'error':
            return (
                <ToastBox variant="warning">
                    <AlertIcon variant="warning" />
                    <p>{t(textClass)}</p>
                </ToastBox>
            );
        case 'registred':
            return (
                <ToastBox variant="warning">
                    <AlertIcon variant="warning" />
                    <p
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                t(textClassRegistered) + getPhoneNumber({ type: 'birdi', isEnd: true }),
                                {
                                    allowedTags: ['span']
                                }
                            )
                        }}
                    />
                </ToastBox>
            );
        default:
            status = null;
    }
    return status;
}

const MembershipRegistrationInsuranceIdValidate = ({
    state,
    validateStatus,
    isValidateJoinStatus,
    insuranceId,
    disableFields,
    isBusy
}: MembershipRegistrationFormValidateInsuranceIdProps) => {
    const { t } = useTranslation();
    const globalLink = useGlobalLink();
    const [isValidated, setIsValidated] = useState(null);
    const { values, touched, errors, handleBlur, handleChange, isSubmitting } = useFormikContext();

    const textClass = useMemo(() => {
        if (validateStatus === 'valid') {
            return 'membership.getStarted.insuranceIdValidateSuccess';
        } else {
            return 'membership.getStarted.insuranceIdValidateFail';
        }
    }, [validateStatus]);

    const textClassInvalid = 'membership.getStarted.insuranceIdInvalidate';
    const textClassAlreadyRegistered = 'membership.getStarted.unsuranceIdAlreadyExists';

    useEffect(() => {
        setIsValidated(isValidateJoinStatus);
    }, [isValidateJoinStatus]);

    return (
        <div
            id="membership-registration-insurance-id-validate"
            className="membership-registration-insurance-id-validate-content"
        >
            <div className="membership-registration-insurance-id-validate">
                <div className="membership-registration-insurance-id-validate-input">
                    <Text
                        name="insuranceId"
                        label={t('forms.membership.personalDetails.insuranceId')}
                        onChange={handleChange}
                        onBlur={(e) => {
                            if (
                                touched?.insuranceId &&
                                values?.insuranceId === '' &&
                                values?.insuranceId !== insuranceId &&
                                isValidateJoinStatus
                            ) {
                                setFieldValue('insuranceId', null);
                            }
                            handleBlur(e);
                        }}
                        showErrorsText={true}
                        disabled={disableFields ? disableFields : false}
                        errors={
                            errors?.insuranceId ? t('forms.membership.personalDetails.errors.insurance') : undefined
                        }
                        value={values?.insuranceId}
                        touched={touched.insuranceId}
                        onFocus={() => globalLink.handleFieldFocus(t('forms.membership.personalDetails.insuranceId'))}
                    />

                    <Button
                        async
                        label={t('membership.getStarted.validate')}
                        className="md-full font-weight-bolder validate-button"
                        type="submit"
                        variant="outline-primary"
                        disabled={isSubmitting}
                        isBusy={isBusy}
                        dataGAFormName="Insured"
                        dataGALocation="Insured"
                    />
                </div>

                {!isSubmitting && isValidated !== null && (
                    <InsuranceStatus
                        validateStatus={validateStatus}
                        textClass={textClass}
                        textClassInvalid={textClassInvalid}
                        textClassRegistered={textClassAlreadyRegistered}
                        t={t}
                    />
                )}
            </div>
        </div>
    );
};

export default MembershipRegistrationInsuranceIdValidate;
