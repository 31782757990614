import React from 'react';
import { CircleDoneProps } from './types';
import './index.style.scss';
import CircleIconDone from 'assets/icons/circle-icon-done.svg';

export const CircleDone: React.FC<CircleDoneProps> = () => {
    return (
        <div className="circle-done__container">
            <img src={CircleIconDone} width={25} alt="done-icon" />
        </div>
    );
};
