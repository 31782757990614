import { TFunction } from 'i18next';
import React from 'react';

import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import './index.style.scss';

export interface AutoRefillLearnMoreModalProps {
    t: TFunction<'translation'>;
}

export const AutoRefillLearnMoreModal: React.FC<AutoRefillLearnMoreModalProps> = ({ t }) => {
    return (
        <BirdiModalContent
            title={t('modals.autoRefillLearnMore.title')}
            body={
                <div className="auto-refill-learn-more-body">
                    <span className="auto-refill-learn-more-label">{t('modals.autoRefillLearnMore.subTitle')}</span>

                    <div className="auto-refill-learn-more-text">
                        <ul className="auto-refill-learn-more-items">
                            <li>{t('modals.autoRefillLearnMore.learnMoreText.paragraphOne')}</li>
                            <li>{t('modals.autoRefillLearnMore.learnMoreText.paragraphTwo')}</li>
                            <li>{t('modals.autoRefillLearnMore.learnMoreText.paragraphThree')}</li>
                            <li>{t('modals.autoRefillLearnMore.learnMoreText.paragraphFour')}</li>
                            <li>{t('modals.autoRefillLearnMore.learnMoreText.paragraphFive')}</li>
                        </ul>
                    </div>

                    <div className="auto-refill-learn-more-note-container">
                        <span className="text-dark auto-refill-learn-more-label">
                            {t('modals.autoRefillLearnMore.noteTitle')}
                        </span>

                        <div className="auto-refill-learn-more-text">
                            <ul className="auto-refill-learn-more-items">
                                <li>{t('modals.autoRefillLearnMore.noteText.paragraphOne')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            }
            icon={'none'}
        />
    );
};
