import { useTranslation } from 'react-i18next';

import Button from 'ui-kit/button/button';
import { Checkbox } from 'ui-kit/checkbox';
import ToastBox from 'ui-kit/toast-box/toast-box';

import { getPhoneNumber } from 'util/globalVariables';

import './membership-auto-refill-section.style.scss';

interface MembershipAutoRefillSectionProps {
    handleChange: (isChecked: boolean) => void;
    handleLearnMoreModal: () => void;
}

const MembershipAutoRefillSection = ({ handleChange, handleLearnMoreModal }: MembershipAutoRefillSectionProps) => {
    const { t } = useTranslation();
    return (
        <div className="membership-auto-refill-section">
            <div>
                <h3 className="profile-title ">{t('membership.autoRefill.profileTitle')}</h3>
            </div>
            <div className="sub-title">
                <div>
                    <p>{t('membership.autoRefill.subSection')}</p>
                </div>
                <div className="sub-title-button">
                    <Button
                        onClick={handleLearnMoreModal}
                        className="p-0 btn-demi text-cerulean no-min-width"
                        variant="text"
                        label={t('membership.autoRefill.learnMore')}
                        type="button"
                    />
                </div>
            </div>
            <div className="section-smoke">
                <div>
                    <div>
                        <h5>{t('membership.autoRefill.termsTitle')}</h5>
                    </div>
                    <div>
                        <p
                            className="paragraph"
                            dangerouslySetInnerHTML={{
                                __html: t('membership.autoRefill.terms', {
                                    phoneNumber: getPhoneNumber({ isEnd: true })
                                })
                            }}
                        ></p>
                    </div>
                </div>
                <div>
                    <div>
                        <Checkbox
                            label={t('membership.autoRefill.checkbox')}
                            id="autoRefill"
                            name="autoRefill"
                            className="membership-auto-refill-section-checkbox"
                            onClick={(isChecked: boolean) => {}}
                            onCheckChanged={(isChecked: boolean) => handleChange(isChecked)}
                        />
                    </div>
                </div>
            </div>
            <div className="px-4 py-2">
                <div className="setting-align-items">
                    <ToastBox icon="warning" variant="warning">
                        <p>{t('membership.autoRefill.setting')}</p>
                    </ToastBox>
                </div>
            </div>
        </div>
    );
};

export default MembershipAutoRefillSection;
